// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const MUTATION = gql`
  mutation DossierTypeListInlineEditForm(
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $columnName: String!,
    $data: JSON
  ) {
    form: dossierTypeListInlineEditForm(
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug,
      columnName: $columnName,
      data: $data
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export default MUTATION;
